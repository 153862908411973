import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import { SendRounded, AlternateEmail , GitHub, LinkedIn, LocationOn, Twitter } from '@mui/icons-material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Alert, Snackbar } from '@mui/material';
import { Section } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';

interface FormValues {
    firstname: string;
    lastname: string;
    email: string;
    message: string;
}

const Contact = () => {
    const { t } = useTranslation();

    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = useState<string>(t("contact.CONTACT-SNACKBAR-SUCCESS-MSG"));
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false);
    
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
      ) => {
        if (reason === "clickaway") {
          return;
        }
        
        setOpenSnackbar(false);
    };
    
    const initialValues: FormValues = {
        firstname: '',
        lastname: '',
        email: '',
        message: ""
    };
    
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .min(2, t("contact.CONTACT-FORM-FIRSTNAME-ERROR-TOO-SHORT"))
            .max(25, t("contact.CONTACT-FORM-FIRSTNAME-ERROR-TOO-LONG"))
            .required(t("contact.CONTACT-FORM-FIRSTNAME-ERROR-REQUIRED")),
        lastname: Yup.string()
        .min(2, t("contact.CONTACT-FORM-LASTNAME-ERROR-TOO-SHORT"))
        .max(25, t("contact.CONTACT-FORM-LASTNAME-ERROR-TOO-LONG"))
        .required(t("contact.CONTACT-FORM-LASTNAME-ERROR-REQUIRED")),
        email: Yup.string()
            .email(t("contact.CONTACT-FORM-EMAIL-ERROR-INVALID"))
            .required(t("contact.CONTACT-FORM-EMAIL-ERROR-REQUIRED")),
        message: Yup.string()
            .min(10, t("contact.CONTACT-FORM-MESSAGE-ERROR-TOO-SHORT"))
            .max(250, t("contact.CONTACT-FORM-MESSAGE-ERROR-TOO-LONG"))
            .required(t("contact.CONTACT-FORM-MESSAGE-ERROR-REQUIRED")),
    });

    const emailJsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const emailJsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const emailJsUserId = process.env.REACT_APP_EMAILJS_USER_ID;
    

    return (
        <Section id='navbar.CONTACT-LABEL' className={'contact'} name='Contact' >
            <div className='section-heading'>
                <h1>{t("navbar.CONTACT-LABEL")}</h1>
            </div>
            <div className='container'>
                <div className='contact-container'>
                    <div className='form-container'>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} 
                            onSubmit={(values:FormValues, actions: FormikHelpers<FormValues>) => {
                                actions.setSubmitting(true);
                                setTimeout(() => {
                                    emailjs.send(
                                        emailJsServiceId as string, 
                                        emailJsTemplateId as string, 
                                        {
                                            from_firstname: values.firstname,
                                            from_lastname: values.lastname,
                                            message: values.message
                                        }, 
                                        emailJsUserId as string)
                                    .then(() => {
                                        actions.setSubmitting(false);
                                        actions.resetForm();
                                        setOpenSnackbar(true);
                                    })
                                    .catch(() => {
                                        actions.setSubmitting(false);
                                        setOpenSnackbar(true);
                                        setMessageSnackbar(t("contact.CONTACT-SNACKBAR-ERROR-MSG"));
                                        setErrorSnackbar(true);
                                    })
                                }, 500)
                            }}
                        >
                            {({ errors, touched, dirty, isValid }) => (
                                <Form>
                                    <div className='input-container'>
                                        <label htmlFor='lastname' className={errors.lastname && touched.lastname ? 'label-content label-content-error'  : 'label-content'}>{errors.lastname && touched.lastname ? errors.lastname : t("contact.CONTACT-FORM-LASTNAME-LABEL")}</label>
                                        <Field id='lastname'  placeholder={t("contact.CONTACT-FORM-LASTNAME-PLACEHOLDER")} name='lastname' className={errors.lastname && touched.lastname ? 'form-input input-content input-content-error'  : 'form-input input-content'} required/>
                                        
                                    </div>
                                    <div className='input-container'>
                                        <label htmlFor='firstname' className={errors.firstname && touched.firstname ? 'label-content label-content-error'  : 'label-content'}>{errors.firstname && touched.firstname ? errors.firstname : t("contact.CONTACT-FORM-FIRSTNAME-LABEL")}</label>
                                        <Field id='firstname' placeholder={t("contact.CONTACT-FORM-FIRSTNAME-PLACEHOLDER")} name='firstname' className={errors.firstname && touched.firstname ? 'form-input input-content input-content-error'  : 'form-input input-content'} required/>
                                    </div>
                                    <div className='input-container'>
                                        <label htmlFor='email' className={errors.email && touched.email ? 'label-content label-content-error'  : 'label-content'}>{errors.email && touched.email ? errors.email : t("contact.CONTACT-FORM-EMAIL-LABEL")}</label>
                                        <Field id='email' placeholder={t("contact.CONTACT-FORM-EMAIL-PLACEHOLDER")}  name='email' className={errors.email && touched.email ? 'form-input input-content input-content-error'  : 'form-input input-content'} required/>
                                    </div>
                                    <div className='input-container'>
                                        <label htmlFor='message' className={errors.message && touched.message ? 'label-content label-content-error'  : 'label-content'}>{errors.message && touched.message ? errors.message : t("contact.CONTACT-FORM-MESSAGE-LABEL")}</label>
                                        <Field id='message' component='textarea' rows={5} placeholder={t("contact.CONTACT-FORM-MESSAGE-PLACEHOLDER")} name='message' className={errors.message && touched.message ? 'form-message input-content input-content-error'  : 'form-message input-content'} required/>
                                    </div>
                                    <div className='submit-container'>
                                        <button className='submit-btn' type='submit' disabled={!isValid && dirty} >
                                        {t("contact.CONTACT-FORM-SUBMIT-LABEL")}  <SendRounded /> 
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className='social-container'>
                        <a target='_blank' rel='noopener noreferrer' href='mailto:fortune.jeoffray@gmail.com' className='social-icon-container'>
                            <div className='social-icon'><AlternateEmail /></div>
                            <p className='social-content'>fortune.jeoffray@gmail.com</p>
                        </a>
                        <a target='_blank' rel='noopener noreferrer' href='https://www.google.com/maps/place/95470+Saint-Witz/' className='social-icon-container'>
                            <div className='social-icon'><LocationOn /></div>
                            <p className='social-content'>Saint-Witz (Ile-de-France)</p>
                        </a>
                        <a target='_blank' rel='noopener noreferrer' href='https://github.com/PhiloJoff' className='social-icon-container'>
                            <div className='social-icon'><GitHub /></div>
                        </a>
                        <a target='_blank' rel='noopener noreferrer' href='https://fr.linkedin.com/in/jeoffray-fortune-b64443173' className='social-icon-container'>
                            <div className='social-icon'><LinkedIn /></div>
                        </a>
                        <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/PhiloJoff' className='social-icon-container'>
                            <div className='social-icon'><Twitter /></div>
                        </a>
                        <img id='socials-img' src='contact/social_media_monochromatic.svg' alt='socials' />
                    </div>
                </div>
                <div>
                    <Snackbar
                        className='snackbar-form'
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={openSnackbar}
                        onClose={handleClose}
                        autoHideDuration={6000}
                        message={messageSnackbar}
                    >
                        <Alert severity={errorSnackbar ? 'error' : 'success'} >
                            {messageSnackbar}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </Section>
    )
};
export default Contact;
