import React, {FunctionComponent, useState} from 'react';
import ProjectEntity from '../../models/ProjectEntity';
import './ProjectCard.css';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    projectProp: ProjectEntity
};

const ProjectCard: FunctionComponent<Props> = ({projectProp}) => {

    const { t } = useTranslation();
    const [project] = useState<ProjectEntity>(projectProp);

    return (

        <div className='project-card'>
            <div className='project-card-img'
                style={{backgroundImage : 'url('+project.image+')'}} >
            </div>
            <div className='project-card-content'>
                <div className='project-card-title'>
                    <h3>{project.name}</h3>
                </div>
                <div className='project-card-description'>
                    <p>{t(project.description)}</p>
                </div>

                <div className='project-card-actions'>
                    <div className='project-card-stacks'>
                        {project.stacks.map(stack =>
                            <Tooltip title={stack.name} key={stack.id}>
                                <img
                                    className='stack-img'
                                    src={('stacks/' + stack.icon)}
                                    alt={stack.name}
                                />

                            </Tooltip>
                        )}
                    </div>

                    <a target='_blank' rel='noopener noreferrer' className='project-link' href={project.link}>Voir <OpenInNewIcon /></a>
                </div>
            </div>

        </div>
    )
}

export default ProjectCard;
