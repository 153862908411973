import React from 'react';
import './Footer.css';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer id='footer' className='footer'>
            <div className='container'>
                <p className='footer-content'>
                    &copy; {t("footer.FOOTER-P1")} <span className='love-icon'><FavoriteOutlinedIcon /></span> {t("footer.FOOTER-P2")}
                </p>
            </div>

        </footer>
    );
};

export default Footer;
