import React, { SyntheticEvent, useState} from 'react';
import './NarBar.css'
import {Box, Button, Drawer, Tabs } from '@mui/material';
import LinkTab from '../LinkTab/LinkTab';
import MenuIcon from '@mui/icons-material/Menu';
import { useScrollSections } from 'react-scroll-section';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';


const NavBar = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setIsOpen(open);
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const sections = useScrollSections();

    return (
        <Box id='navBar'>
            <Box id="main-navBar" >
                <LanguageSwitcher />
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Tabs for NavBar"
                >
                    {sections.map(({id, onClick}) => <LinkTab key={id} label={id} onClick={onClick} />)}
                </Tabs>
                <div className='navbar-btn-home' onClick={goToTop} id='navbar-btn-home'>
                    <img id='logo' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/logo.png'} alt='logo'/>
                </div>
            </Box>
            <Box id='mobile-navBar'>
                <div className='navbar-btn-home' onClick={goToTop} id='navbar-btn-home'>
                    <img id='logo' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/logo.png'} alt='logo'/>
                </div>
                <Button onClick={toggleDrawer(true)} className='navbar-btn' id='navbar-open-btn'><MenuIcon  /></Button>
                    <Drawer
                        anchor='left'
                        open={isOpen}
                        onClose={toggleDrawer(false)}
                        id='mobile-menu-drawer'
                    >
                        
                        <div className="home_bubble">
                            <div className="bubble b_one"></div>
                            <div className="bubble b_three"></div>
                            <div className="bubble b_four"></div>
                            <div className="bubble b_five"></div>
                            <div className="bubble b_six"></div>
                        </div>
                        <div id='mobile-menu'>
                            <div id="mobile-menu-header">
                                <div id="language-selector-container-mobile">
                                    <LanguageSwitcher />
                                </div>
                                <div className='navbar-btn-home-drawer' onClick={goToTop} id='navbar-btn-home'>
                                    <img id='logo' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/logo.png'} alt='logo'/>
                                </div>
                            </div>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="Tabs for NavBar"
                                onClick={toggleDrawer(false)}
                            >
                                {sections.map(({id, onClick}) => <LinkTab key={id} label={id} onClick={onClick} />)}
                            </Tabs>
                        </div>
                    </Drawer>

            </Box>
        </Box>
    )
};

export default NavBar;