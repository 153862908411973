import * as React from "react";
import Tab from "@mui/material/Tab";
import "./LinkTab.css";
import { useTranslation } from "react-i18next";

interface LinkTabProps {
    label: string;
    key: string;
    onClick: any;
}

function LinkTab(props: LinkTabProps) {
    const { t } = useTranslation();
    
    return (
        <Tab
            className='linkTab' 
            component="span"
            {...props}
            label={t(props.label)}
        />
    );
}

export default LinkTab;