import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationFR from './i18n/fr.json';
import translationEN from './i18n/en.json';
import { Language } from './enums/Language';

const resources = {
    fr: {
        translation: translationFR
    },
    en: {
        translation: translationEN
    }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.FR,
    lng: Language.FR,
    debug: true,
    resources,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;