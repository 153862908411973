import React from 'react';
import './Portfolio.css'
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import ProjectEntity from '../../models/ProjectEntity';
import StackEntity from '../../models/StackEntity';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Section } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';

const Portfolio = () => {


    const projects: ProjectEntity[] = [
        {
            id: 1,
            name: 'Begood',
            description: "portfolio.PORTFOLIO-PROJECT-BEGOOD",
            image: 'portfolio/begood.png',
            stacks: [
                new StackEntity(1,'Java', 'java.svg'),
                new StackEntity(2, 'AngularJS', 'angular.svg'),
                new StackEntity(3, 'Javascript', 'js.svg'),
                new StackEntity(4, 'Typescript', 'ts.svg'),
                new StackEntity(5, 'HTML', 'html.svg'),
                new StackEntity(6, 'CSS', 'css.svg')
            ],
            link: 'http://begood-transformation.com/'

        },
        {
            id: 2,
            name: 'Service-public.fr',
            description:"portfolio.PORTFOLIO-PROJECT-SP",
            image: 'portfolio/sp.png',
            stacks: [
                new StackEntity(1, 'Java', 'java.svg'),
                new StackEntity(2, 'Thymeleaf', 'thymeleaf.svg'),
                new StackEntity(3, 'Keycloak', 'keycloak.svg'),
                new StackEntity(4, 'CSS', 'css.svg')
            ],
            link: 'https://www.service-public.fr/'

        },
        {
            id: 3,
            name: 'CaseBreaker',
            description:"portfolio.PORTFOLIO-PROJECT-CASEBREAKER",
            image: 'portfolio/caseBreaker.jpg',
            stacks: [
                new StackEntity(1, 'C#', 'c-sharp.svg'),
                new StackEntity(2, 'Monogame', 'monogame.svg')
            ],
            link: 'https://github.com/PhiloJoff/CaseBreaker'
    
        },
        {
            id: 4,
            name: 'GTSAV-Thymleaf',
            description:"portfolio.PORTFOLIO-PROJECT-GTSAV-THYMLEAF",
            image: 'portfolio/default.jpg',
            stacks: [
                new StackEntity(1, 'JAVA', 'java.svg'),
                new StackEntity(2, 'Spring', 'spring.svg'),
                new StackEntity(3, 'Thymeleaf', 'thymeleaf.svg')
            ],
            link: 'https://github.com/PhiloJoff/gtsav-thymleaf'
    
        },
        {
            id: 5,
            name: 'GTSAV-Frontend-Angular',
            description:"portfolio.PORTFOLIO-PROJECT-GTSAV-FRONTEND-ANGULAR",
            image: 'portfolio/default.jpg',
            stacks: [
                new StackEntity(1, 'Angular', 'angular.svg'),
                new StackEntity(2, 'Typescript', 'ts.svg'),
                new StackEntity(3, 'HTML', 'html.svg'),
                new StackEntity(4, 'CSS', 'css.svg')
            ],
            link: 'https://github.com/PhiloJoff/gtsav-frontend-angular'
    
        },
        {
            id: 6,
            name: 'GTSAV-Backend-Java',
            description:"portfolio.PORTFOLIO-PROJECT-GTSAV-BACKEND-JAVA",
            image: 'portfolio/default.jpg',
            stacks: [
                new StackEntity(1, 'JAVA', 'java.svg'),
                new StackEntity(2, 'Spring', 'spring.svg')
            ],
            link: 'https://github.com/PhiloJoff/gtsav-backend-java'
    
        },
        {
            id: 7,
            name: 'Portfolio',
            description:"portfolio.PORTFOLIO-PROJECT-PORTFOLIO",
            image: 'portfolio/philo-portfolio.png',
            stacks: [
                new StackEntity(1, 'React', 'react.svg'),
                new StackEntity(2, 'Typescript', 'ts.svg'),
                new StackEntity(3, 'HTML', 'html.svg'),
                new StackEntity(4, 'CSS', 'css.svg')
            ],
            link: 'https://github.com/PhiloJoff/philo-portfolio'
    
        },
        {
            id: 8,
            name: 'DgDécoration',
            description:"portfolio.PORTFOLIO-PROJECT-DGD",
            image: 'portfolio/dgd.png',
            stacks: [
                new StackEntity(1, 'React', 'react.svg'),
                new StackEntity(2, 'Typescript', 'ts.svg'),
                new StackEntity(3, 'HTML', 'html.svg'),
                new StackEntity(4, 'CSS', 'css.svg')
            ],
            link: 'https://www.dgdecoration.com/'

        },
        {
            id: 9,
            name: 'GTSAV-Frontend-AngularJS',
            description:"portfolio.PORTFOLIO-PROJECT-GTSAV-FRONTEND-ANGULARJS",
            image: 'portfolio/default.jpg',
            stacks: [
                new StackEntity(1, 'AngularJS', 'angular.svg'),
                new StackEntity(2, 'Javascript', 'js.svg'),
                new StackEntity(3, 'HTML', 'html.svg'),
                new StackEntity(4, 'CSS', 'css.svg')
            ],
            link: 'https://github.com/PhiloJoff/gtsav-frontend-angular'
    
        },
        {
            id: 10,
            name: 'GTSAV-Backend-AngularJS',
            description:"portfolio.PORTFOLIO-PROJECT-GTSAV-BACKEND-JAVA2",
            image: 'portfolio/default.jpg',
            stacks: [
                new StackEntity(1, 'JAVA', 'java.svg'),
                new StackEntity(2, 'Spring', 'spring.svg')
            ],
            link: 'https://github.com/PhiloJoff/gtsav-frontend-angular'
    
        }
        
    
    ];
    
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false
                }
            }
        ]
    };
    const { t } = useTranslation();

    return (
        <Section id='navbar.PORTFOLIO-LABEL' className={'portfolio'}>
            <div className='section-heading'>
                <h1>{t("navbar.PORTFOLIO-LABEL")}</h1>
            </div>
            <div className={'container'}>
                <div id='portfolio-container'>
                    <Slider {...sliderSettings}>
                        {projects.map((project, index) => (
                                <ProjectCard key={project.name} projectProp={project} />
                        ))}
                    </Slider>
                </div>
            </div>
        </Section>
    );
};

export default Portfolio;