import React, { useState } from 'react';
import ExperienceEntity from '../../models/ExperienceEntity';
import {TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent} from '@mui/lab'
import { useTranslation } from 'react-i18next';

interface TimelineContentExperienceProps {
    experience: ExperienceEntity;
    side: string;
}

const TimelineContentExperience = (props: TimelineContentExperienceProps) => {
    const { t } = useTranslation();
    const [experience] = useState<ExperienceEntity>(props.experience);
    const [side] = useState<string>(props.side);
    return (
        <>
            <TimelineItem>
                <TimelineOppositeContent>
                    {side ==='left' ? 
                        <div>
                            <div>
                                <h2>
                                    {experience.yearStart} - {experience.yearEnd}
                                </h2>
                                <h3>
                                    {experience.enterprise}
                                </h3>
                            </div>
                        </div> 
                        : 
                         <div>
                            <div>
                                <div>
                                    <h2>
                                        {t(experience.name)}
                                    </h2>
                                    <h3>{experience.location}</h3>
                                    <p>
                                        {t(experience.description)}
                                    </p>
                                </div>
                            </div>
                        </div>
                     }
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    
                    {side ==='left' ? 
                         <div>
                            <div>
                                <div>
                                    <h2>
                                        {t(experience.name)}
                                    </h2>
                                    <h3>{experience.location}</h3>
                                    <p>
                                        {t(experience.description)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        : 
                        <div>
                            <div>
                                <h2>
                                    {experience.yearStart} - {experience.yearEnd}
                                </h2>
                                <h3>
                                    {experience.enterprise}
                                </h3>
                            </div>
                        </div> 
                     }
                </TimelineContent>
            </TimelineItem>
        </>
    );
};

export default TimelineContentExperience;