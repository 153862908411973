import React from 'react';
import NavBar from '../NavBar/NavBar';
import Home from '../../pages/Home/Home';
import Experience from '../../pages/Experience/Experience';
import Portfolio from '../../pages/Portfolio/Portfolio';
import Education from '../../pages/Education/Education';
import Contact from '../../pages/Contact/Contact';
import Footer from "../Footer/Footer";
import About from '../../pages/About/About';
import { ScrollingProvider } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';

interface ContentComponent {
    name: string;
    key: string;
    component : JSX.Element;
}

const Content = () => {

    const { t } = useTranslation();

    const links: ContentComponent[] = [
        {
            key: 'home',
            name: t("HOME-LABEL"),
            component: Home()
        },
        {
            key: 'about',
            name: t("ABOUT-LABEL"),
            component: About()
        },
        {
            key: 'portfolio',
            name: t("PORTFOLIO-LABEL"),
            component: Portfolio()
        },
        {
            key: 'experience',
            name: t("EXPERIENCE-LABEL"),
            component: Experience()
        },
        {
            key: 'education',
            name: t("EDUCATION-LABEL"),
            component: Education()
        },
        {
            key: 'contact',
            name: t("CONTACT-LABEL"),
            component: Contact()
        }

    ];


    return (
        <div>
            <ScrollingProvider>
                <NavBar />
                {links.map( (link: { component: any; }) => link.component)}
            </ScrollingProvider>
            <Footer />
        </div>
    );
};

export default Content;