import React, { useState} from 'react';
import EducationEntity from "../../models/EducationEntity";
import './Education.css'
import { Section } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';


const Education = () => {
    const { t } = useTranslation();
    const [educations] = useState<EducationEntity[]>([
        {
            id : 1,
            name: "education.EDUCATION-SCHOOL1-NAME",
            description : "education.EDUCATION-SCHOOL1-DESCRIPTION",
            school: "LYCÉE JOSEPH GAILLARD • Fort-de-France, Martinique",
            yearStart: 2008,
            yearEnd: 2010
        },
        {
            id : 2,
            name: "education.EDUCATION-SCHOOL2-NAME",
            description : "education.EDUCATION-SCHOOL2-DESCRIPTION",
            school: "UNIVERSITÉ PARIS XIII - INSTITUT GALILÉE • Villetaneuse, France",
            yearStart: 2010,
            yearEnd: 2013
        },
        {
            id : 3,
            name: "education.EDUCATION-SCHOOL3-NAME",
            description : "education.EDUCATION-SCHOOL3-DESCRIPTION",
            school: "Doranco • Paris, France",
            yearStart: 2018,
            yearEnd: 2019
        }
    ]);


    return (
        <Section id='navbar.EDUCATION-LABEL' className={'education'} name='Education' >
            <div className="section-heading">
                <h2>{t("navbar.EDUCATION-LABEL")}</h2>
            </div>
            
            <div className='container'>
                <div className='education-content'>
                    <ul className="timeline">
                        {
                            educations.map((education, index) => (
                                <li key={education.id} className= {index % 2 === 0 ? '' : 'timeline-inverted'}>
                                    <div className="posted-date">
                                        <span className="month wow fadeInRight">{education.yearStart} - {education.yearEnd}</span>
                                    </div>
                                    <div className="timeline-panel wow fadeInLeft">
                                        <div className="timeline-content">
                                            <div className="timeline-heading">
                                                <h3>{t(education.name)}</h3>
                                                <span>{education.school}</span>
                                            </div>

                                            <div className="timeline-body">
                                                <p>{t(education.description)}</p>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                )).sort().reverse()
                        }
                    </ul>
                </div>
            </div>
        </Section>
    );
};

export default Education;
