import React, { useState } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from '../../enums/Language';
import "./LanguageSwitcher.css";
 
const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState<string>(i18n.language);
 
    const changeLanguage = (language: string) => {
        setLang(language);
        i18n.changeLanguage(language);
        setIsOpen(false);
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
 
    return (
        <div className="languages-selector-container">
            <div className="language-selected" 
                onClick={toggleOpen}
            >
                <img className="language-image"
                    src={lang === 'fr' ? process.env.PUBLIC_URL+'/navbar/fr-flag.png' : process.env.PUBLIC_URL+'/navbar/en-flag.png'} 
                    alt={lang === 'fr' ? 'fr' : 'en'} 
                />
                <span>{lang === 'fr' ? 'Français' : 'English'}</span>
            </div>
            <div className="languages-selector-content"
                style={{display :  isOpen ? 'flex' : 'none' }}
            >
                <div style={{display :  lang === "en" ? 'flex' : 'none' }}
                    onClick={() =>changeLanguage(Language.FR)}
                    className="language-container"
                >
                    <img className="language-image"
                        src={process.env.PUBLIC_URL+'/navbar/fr-flag.png'} 
                        alt={'fr'} 
                    />
                    <span>Français</span>
                </div>
                <div style={{display :  lang === "fr" ? 'flex' : 'none' }}
                    onClick={() => changeLanguage(Language.EN)}
                    className="language-container"
                >
                    <img className="language-image"
                        src={process.env.PUBLIC_URL+'/navbar/en-flag.png'} 
                        alt={'en'} 
                    />
                    <span>English</span>
                </div>

            </div>
        </div>
    )
}
 
export default LanguageSwitcher;