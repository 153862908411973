import React, { useEffect, useState } from 'react';
import './Home.css';
import { Section } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    
    const [contactSection, setContactSection] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setContactSection(document.getElementById('navbar.CONTACT-LABEL'));
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if(contactSection)
            contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <Section id='navbar.HOME-LABEL' className='home' name={t("navbar.HOME-LABEL")}>
            <div className="home_bubble">
                <div className="bubble b_one"></div>
                <div className="bubble b_two"></div>
                <div className="bubble b_three"></div>
                <div className="bubble b_four"></div>
                <div className="bubble b_five"></div>
                <div className="bubble b_six"></div>
            </div>
            <div className={'container'}>
                <div id='presentationContainer'>

                    <h1 className='home-title'>Hello World !</h1>
                    <div className='title-box'>
                        <h1 className='home-title'>{t("home.HOME-TITLE")}</h1>
                    </div>
                    <div className='subtitle-box'>
                        <h2 className='home-subtitle'>{t("home.HOME-SUBTITLE")}</h2>
                    </div>
                    <div className='btn-box'>
                        <a className='btn-home' href='./files/CV_Jeoffray_FORTUNE.pdf' download>{t("home.HOME-BTN-RESUME")}</a>
                        <span className='btn-home btn-home-contact' onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { handleClick(event)}} >{t("home.HOME-BTN-CONTACT")}</span>
                    </div>
                </div>
            </div>
            <div id='imagesContainer'>
                <div id='shadowContainer' className='scale-in-center' >
                    <img id='shadow' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/shadow.png'} alt='shadow'/>
                </div>
                <div id='htmlContainer' className='scale-in-center' >
                    <img id='html' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/html.png'} alt='html'/>
                </div>
                <div id='javaContainer' className='scale-in-center' >
                    <img id='java' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/java.png'} alt='java'/>
                </div>
                <div id='sqlContainer' className='scale-in-center' >
                    <img id='sql' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/sql.png'} alt='sql'/>
                </div>
                <div id='springContainer' className='scale-in-center' >
                    <img id='spring' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/spring.png'} alt='spring'/>
                </div>
                <div id='angularContainer' className='scale-in-center' >
                    <img id='angular' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/angular.png'} alt='angular'/>
                </div>
                <div id='reactContainer' className='scale-in-center' >
                    <img id='react' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/react.png'} alt='react'/>
                </div>
                <div id='deskContainer' className='scale-in-center' >
                    <img id='desk' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/desk.png'} alt='desk'/>
                </div>
                <div id='ondeskContainer' className='scale-in-center' >
                    <img id='ondesk' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/ondesk.png'} alt='ondesk'/>
                </div>
                <div id='devContainer' className='scale-in-center' >
                    <img id='dev' className='scale-in-center' src={process.env.PUBLIC_URL+'/home/dev_seat.png'} alt='dev'/>
                </div>
            </div>
        </Section>
    );
};

export default Home;