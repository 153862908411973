import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Content from './components/Content/Content';
import {createTheme, ThemeOptions, ThemeProvider} from "@mui/material";

export const themeOptions: ThemeOptions = createTheme({
    palette: {
        primary: {
            main: '#552ef4fc',
        },
        secondary: {
            main: '#5266ff',
        },
    },
});

function App() {
    
  return (
    <ThemeProvider theme={themeOptions}>
        <BrowserRouter>
            <Routes>
                <Route path={process.env.REACT_APP_BASE_PATH} element={<Content/>} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
