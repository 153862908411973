import React from 'react';
import Marquee from 'react-fast-marquee';
import StackEntity from '../../models/StackEntity';
import './About.css';

//import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import ProjectCard from "../../components/ProjectCard/ProjectCard";
import { Section } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';


const About = () => {
    const stacksTech: StackEntity[] = [
        {
            id: 1,
            name: "Java",
            icon: "java.png"
        },
        {
            id: 2,
            name: "Spring",
            icon: "spring.png"
        },
        {
            id: 3,
            name: "PostgreSQL",
            icon: "postgresql.png"
        },
        {
            id: 4,
            name: "Oracle",
            icon: "oracle.png"
        },
        {
            id: 5,
            name: "Thymeleaf",
            icon: "thymeleaf.png"
        },
        {
            id: 6,
            name: "JS",
            icon: "js.png"
        },
        {
            id: 7,
            name: "TS",
            icon: "ts.png"
        },
        {
            id: 8,
            name: "HTML",
            icon: "html.png"
        },
        {
            id: 9,
            name: "CSS",
            icon: "css.png"
        },
        {
            id: 10,
            name: "Angular",
            icon: "angular.png"
        },
        {
            id: 11,
            name: "React",
            icon: "react.png"
        },
        {
            id: 12,
            name: "Perl",
            icon: "perl.png"
        },
        {
            id: 13,
            name: "Jenkins",
            icon: "jenkins.png"
        },
        {
            id: 14,
            name: "Docker",
            icon: "docker.png"
        },
        {
            id: 15,
            name: "Ansible",
            icon: "ansible.png"
        },
        {
            id: 16,
            name: "Terraform",
            icon: "terraform.png"
        },
        {
            id: 17,
            name: "Keycloak",
            icon: "keycloak.png"
        }
    ];

    const stacksFonct: StackEntity[] = [
        {
            id: 1,
            name: "Agile",
            icon: "agile.png"
        },
        {
            id: 2,
            name: "Slack",
            icon: "slack.png"
        },
        {
            id: 3,
            name: "ClickUp",
            icon: "clickup.png"
        },
        {
            id: 4,
            name: "Notion",
            icon: "notion.png"
        },
        {
            id: 5,
            name: "Photoshop",
            icon: "photoshop.png"
        },
        {
            id: 6,
            name: "Office 365",
            icon: "office365.png"
        },
        {
            id: 7,
            name: "Clean Code",
            icon: "clean-code.png"
        },
        {
            id: 8,
            name: "Figma",
            icon: "figma.png"
        },
        {
            id: 9,
            name: "Jira",
            icon: "jira.png"
        }
    ];
    
    const { t } = useTranslation();

    return (
        <Section id="navbar.ABOUT-LABEL" name='About' >
            <div className="section-heading">
                <h1>{t("navbar.ABOUT-LABEL")}</h1>
            </div>
            <div className='container'>
                <p>
                    {t("about.ABOUT-CONTENT-P1")}
                </p>
                <p>
                    {t("about.ABOUT-CONTENT-P2")}
                </p>
                <p>
                   <b>
                        {t("about.ABOUT-CONTENT-P3")}
                    </b>
                </p>
            </div>
            <div id="tech-slider">
                <Marquee
                    gradient={false} 
                    speed={80} 
                    pauseOnHover={true}
                    pauseOnClick={true} 
                    delay={0}
                    play={true} 
                    direction="left"
                >
                {
                    stacksTech.map( stack => (
                        <div key={stack.id} className='stack-box'>
                            <img alt={stack.name} src={'about/'+stack.icon} />
                            <h3>{stack.name}</h3>
                        </div>    
                    ))
                }
                </Marquee>
            </div>
            <div id="fonct-slider">
                <Marquee
                    gradient={false} 
                    speed={65} 
                    pauseOnHover={true}
                    pauseOnClick={true} 
                    delay={0}
                    play={true} 
                    direction="right"
                >
                {
                    stacksFonct.map( stack => (
                        <div key={stack.id} className='stack-box'>
                            <img alt={stack.name} src={'about/'+stack.icon} />
                            <h3>{stack.name}</h3>
                        </div>    
                    ))
                }
                </Marquee>
            </div>
            {/*<div>
                <Slider {...sliderSettings}>
                    {
                        stacksFonct.map( stack => (
                            <div key={stack.id} className='stack-box'>
                                <img alt={stack.name} src={'about/'+stack.icon} />
                                <h3>{stack.name}</h3>
                            </div>
                        ))
                    }
                </Slider>
            </div>*/}
        </Section>
    );
};

export default About;