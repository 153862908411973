import React, {useState} from 'react';
import './Experience.css'
import ExperienceEntity from "../../models/ExperienceEntity";
import TimelineContentExperience from '../../components/TimelineContentExperience/TimelineContentExperience';
import { Section } from 'react-scroll-section';
import { useTranslation } from 'react-i18next';

const Experience = () => {
    const { t } = useTranslation();
    const [experiences] = useState<ExperienceEntity[]>([
        {
            id: 1,
            name: "experience.EXPERIENCE-JOB2-NAME",
            description : "experience.EXPERIENCE-JOB2-DESCRIPTION",
            location : "Paris",
            enterprise : "PROTELCO",
            yearStart : "2015" ,
            yearEnd :  "2019"
        },
        {
            id: 2,
            name: "experience.EXPERIENCE-JOB3-NAME",
            description : "experience.EXPERIENCE-JOB3-DESCRIPTION",
            location : "Paris",
            enterprise : "MEREO",
            yearStart : "2019" ,
            yearEnd :  "2022"
        },
        {
            id: 3,
            name: "experience.EXPERIENCE-JOB4-NAME",
            description : "experience.EXPERIENCE-JOB4-DESCRIPTION",
            location : "Paris",
            enterprise : "Dila",
            yearStart : "2022" ,
            yearEnd :  ""
        }
    ]);

    return (
        <Section id='navbar.EXPERIENCE-LABEL' className={'experience'} name='Experience' >
            <div className="section-heading">
                <h2>{t("navbar.EXPERIENCE-LABEL")}</h2>
            </div>
            <div className='container'>
                {experiences.map((experience, index) => (
                    <TimelineContentExperience experience={experience} side={index % 2 === 0 ? "right" : "left"} />
                )).sort().reverse()}
            </div>
        </Section>
    );
}

export default Experience;